@import '../../styles/variables';

$cta-image-blur: -110px 0px 60px 0px $paywall-background;

.ctaWrap {
  border-radius: 0;
  justify-content: center;

  @media (min-width: $tabletLg) {
    border-radius: 16px;
    justify-content: right;
  }
}

.ctaContent {
  box-shadow: $cta-image-blur;
  border-radius: 0;
  margin: 40px auto auto auto;
  background-color: $paywall-background;
  justify-content: flex-start;
  max-width: 100%;
  min-width: 100%;
  position: relative;
  text-align: center;
  height: unset;

  @media (min-width: $tabletLg) {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    margin: 0;
    padding: 40px 60px 0 60px;
    max-width: 580px;
    min-width: 580px;
    text-align: left;
  }
}

.ctaTitle {
  margin: 0 auto 10px auto;
  max-width: 580px;

  @media (min-width: $tabletLg) {
    margin: 0 0 10px 0;
  }
}

.ctaBody {
  margin: 0 auto 30px auto;
  max-width: 580px;

  @media (min-width: $tabletLg) {
    margin: 0 0 30px 0;
  }
}

.ctaButton {
  margin: 0 auto;
  max-width: 155px;

  @media (min-width: $tabletLg) {
    margin: 0;
  }
}
